@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
  .footer_container {
    @apply py-4 px-5 z-10  max-w-[1440px] mx-auto w-full
  }
  .footer_avatar {
    @apply rounded-full border-[5px] mx-auto sm:mx-2 border-main-bg h-[240px] w-[240px] mb-6
  }
  .light_text_small {
    @apply text-main-bg text-[14px]
  }
  .light_text_lg {
    @apply text-main-bg text-[18px]
  }
  .footer_dark_text{
    @apply text-dark-font tracking-[1px] text-xs/[10px] border-t-[1px] pt-6 border-brown-bg leading-[1.357] font-GTPressura-Light text-justify
  }
  .text_phone_number {
    @apply text-main-bg flex flex-col after:bg-blue-gray-50 after:transition-all after:h-[1px]
  }
  .footer_form_input {
    @apply p-4 w-full focus:outline-none text-main-bg border-[1px] border-main-bg
  }
  .footer_form_button {
    @apply bg-brown-bg p-4 w-full md:w-[100px] text-left text-main-bg text-[20px] font-sans hover:text-brown-bg hover:bg-main-bg mt-2
  }
  .gallery-select-container{
    @apply m-auto w-full sm:w-1/2 bg-[#1c4587] border-[1px] text-main-bg rounded-none outline-none p-1
  }
}


body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.home_video:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 105%;
    background-color: rgba(0,0,0,.5);
    z-index: 1;
}
@font-face {
    font-family: 'GTPressura-Bold';
    src: url('./assets/fonts/GTPressura-Bold/GTPressura-Bold.woff2') format('woff2'),
    url('./assets/fonts/GTPressura-Bold/GTPressura-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'GTPressura-Light';
    src: url('./assets/fonts/GTPressura-Light/GTPressura-Light.woff2') format('woff2'),
    url('./assets/fonts/GTPressura-Light/GTPressura-Light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'GTPressura';
    src: url('./assets/fonts/GTPressura/GTPressura.woff2') format('woff2'),
    url('./assets/fonts/GTPressura/GTPressura.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'SctoGroteskA-Light';
    src: url('./assets/fonts/SctoGroteskA-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'SctoGroteskADemo-Regular';
    src: url('./assets/fonts/SctoGroteskADemo-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  .splash-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 1.2s ease-out, opacity 0.2s ease-out;
    transform: translateX(0);
    opacity: 1;
  }
  @keyframes slideOutTop {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    60% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(-100%);
      opacity: 1;
    }
  }
  
  .slide-out-top {
    animation-name: slideOutTop;
    animation-duration: 2s; /* Adjust duration as needed */
    animation-fill-mode: forwards; /* Keeps the final state after animation ends */
  }
  .splash-screen.hidden {
    opacity: 0; /* Hide the splash screen when opacity is 0 */
    pointer-events: none; /* Disable pointer events when hidden */
  }


  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .slow-spin {
    animation: spin 2s linear infinite; /* Adjust the duration (5s in this case) to control the speed */
  }

  .page-button a:before {
    content: "";
    position: absolute;
    left: 30px;
    top: 28px;
    width: 7px;
    height: 7px;
    border-bottom: 1px solid #F0EBE2;
    border-right: 1px solid #F0EBE2;
    -webkit-transform: translateX(3px) translateY(-50%) translateZ(0) rotate(140deg) scale(1,1);
    -moz-transform: translateX(3px) translateY(-50%) translateZ(0) rotate(140deg) scale(1,1);
    -ms-transform: translateX(3px) translateY(-50%) translateZ(0) rotate(140deg) scale(1,1);
    -o-transform: translateX(3px) translateY(-50%) translateZ(0) rotate(140deg) scale(1,1);
    transform: translateX(3px) translateY(-50%) translateZ(0) rotate(140deg) scale(1,1);
}

.page-button-next a:before {
  content: "";
  position: absolute;
  right: 30px;
  top: 28px;
  width: 7px;
  height: 7px;
  border-bottom: 1px solid #F0EBE2;
  border-right: 1px solid #F0EBE2;
  -webkit-transform: translateX(-3px) translateY(-50%) translateZ(0) rotate(-45deg) scale(1,1);
  -moz-transform: translateX(-3px) translateY(-50%) translateZ(0) rotate(-45deg) scale(1,1);
  -ms-transform: translateX(-3px) translateY(-50%) translateZ(0) rotate(-45deg) scale(1,1);
  -o-transform: translateX(-3px) translateY(-50%) translateZ(0) rotate(-45deg) scale(1,1);
  transform: translateX(-3px) translateY(-50%) translateZ(0) rotate(-45deg) scale(1,1);
}

.animate-duration {
  transition: background-color 0.7s ease, color 0.7s ease
}
.animate-font-duration {
  transition: background-color 1.5s ease, color 1.5s ease
}
.animate-duration-fast {
  transition: background-color 0.3s ease, color 0.3s ease
}

.animate-section-duration {
  transition: background-color 0.8s ease, color 0.8s ease
}

.scroll-to-top-button {
  z-index: 100 !important;
  padding: 6px;
  border-radius: 100px !important;
  border-width: 3px !important;
  background: #453421;
}