.hover-after:hover::after {
    width:  96px;
  }
.hover-after::after {
    width:  0%;
}
.email1-hover-after:hover::after {
    width:  168px;
  }
.email1-hover-after::after {
    width:  0%;
}
.email2-hover-after:hover::after {
    width:  178px;
  }
.email2-hover-after::after {
    width:  0%;
}
option {
    background-color: #F1ECE2;
    border-top-width: 1px;
    border-color: #153644;
    height: 60px;
    border-radius: 0px;
}